<template>
  <div
    class="row items-center justify-center with-bgimg"
    style="height: 100vh; background-image: url('../img/bg.jpg');"
  >
    <div v-if="!hiddenSplash">
      <q-card flat bordered style="width: 300px; ">
        <q-card-section class="text-center">
          <img
            :src="`../img/fortebond.png`"
            spinner-color="white"
            style="width:250px"
          />
          <a class="text-h6 text-bold">Research And Development</a>
          <br />
          <a class="text-h6 text-bold">Application</a>
          <br />
          <a class="text-caption text-black">Masukkan username dan password</a>
        </q-card-section>
        <q-card-section class="q-gutter-sm q-mb-sm">
          <q-input
            outlined
            v-model="user.username"
            @keyup.enter="$refs.input_password.focus()"
            ref="input_username"
          >
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            outlined
            v-model="user.password"
            ref="input_password"
            @keyup.enter="login"
            :type="isPwd ? 'password' : 'text'"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </q-card-section>
        <q-btn
          color="warning"
          text-color="black"
          class="full-width no-border-radius"
          @click="login"
          ><span class="text-bold" @click="login">LOGIN</span></q-btn
        >
      </q-card>
    </div>
    <div
      v-if="hiddenSplash"
      id="theSplash"
      class="splash row justify-center"
      @click="hiddenSplash = false"
    >
      <div
        class="bg-mode"
        style="width:100vw; height:100vh;"
        :style="
          is_desktop
            ? `background-image: url('../img/bg_desktop.jpg');`
            : `background-image: url('../img/bg_mobile.jpg');`
        "
      >
        <div :class="is_desktop ? 'row justify-end' : 'row justify-center'">
          <div class="q-mr-md q-mt-md">
            <img
              :src="`../img/fortebond.png`"
              style="padding-top: 5.2%; width:300px"
              spinner-color="white"
            />
            <div
              class="my-font text-bold text-h6"
              :class="is_desktop ? 'text-white' : 'text-grey-600'"
            >
              Research And Development
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
export default {
  setup() {
    let $http = inject("$http");
    const $q = useQuasar();
    let router = useRouter();
    let user = ref({});
    let login = async () => {
      const MD5 = require("md5");
      let payload = {
        username: user.value.username,
        password: MD5(user.value.password),
      };
      try {
        let resp = await $http.post("/login", payload);
        $q.notify({ message: "Proses login Berhasil!", color: "positive" });
        localStorage.setItem("token", resp.data);

        user.value = {};

        let token = resp.data;
        toHomePage(token);
      } catch (error) {
        $q.notify({ message: "Proses login Gagal!", color: "negative" });
        console.log("gagal login");
        console.log(error);
      }
    };
    let toHomePage = async (token) => {
      let resp = await $http.post(`/user_data`, {
        token: token,
      });
      let user = resp.data;

      if (user.is_manager == "1") {
        router.push("/app/statistic");
      } else if (user.is_purchasing == "1") {
        router.push("/app/test/list");
      } else {
        router.push("/app/development/list");
      }
    };

    onMounted(() => {
      splasher();
    });

    //splashscreen area
    let hiddenSplash = ref(false);
    let is_desktop = ref(true);

    let splasher = () => {
      if ($q.screen.height > $q.screen.width) {
        is_desktop.value = false;
      }
      hiddenSplash.value = true;
      const splash = document.getElementById("theSplash");
      setTimeout(() => {
        // setelah timeout splash hilang, dan z-index mundur
        // splash.classList.add("display-none");
        hiddenSplash.value = false;
      }, 5000);
    };

    return {
      isPwd: ref(true),
      user,
      login,
      hiddenSplash,
      is_desktop,
      splasher,
    };
  },
};
</script>
<style lang="scss">
.with-bgimg {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: gray;
  background-blend-mode: multiply;
}
</style>
<style lang="scss" scoped>
.bg-mode {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  z-index: 200;
  color: black;
  text-align: center;

  opacity: 0;
  animation: fadeIn 5s ease-in-out forwards;
}

.splash.display-none {
  position: fixed;
  // display: none;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  z-index: -200;
  color: white;
  text-align: center;
  line-height: 90vh;
  transition: all 1s;
}

@keyframes fadeIn {
  // to {
  //   opacity: 1;
  // }
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards;
}

@font-face {
  font-family: customfont;
  src: url(../css/fonts/Monopola.ttf);
}

.my-font {
  font-family: "customfont";
}

.with-bgimg {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: gray;
  background-blend-mode: multiply;
}
</style>
